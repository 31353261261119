import React, { useEffect, useState } from "react";
import moment from 'moment';
import bnDate from './bn-date';

const TodayDate = () => {
	const [ dateNow, setDateNow ] = useState( new bnDate() );

	moment.locale('en');

	useEffect( () => {
		const tick = setInterval( () => {
			setDateNow( new bnDate() );
		}, 1000 );

		return () => {
			clearTimeout( tick );
		}
	}, [] )
	return (
		<div className="today-date-time">
			Today:
			{ moment( dateNow.inputDate ).format( ' dddd, D MMMM, YYYY' ) }
			{ dateNow.convert( ', l, j F, Y (বঙ্গাব্দ)' ) }
		</div>
	);
};

export default TodayDate;
