import React from "react"
import { graphql } from "gatsby"
import { includes } from 'lodash';


import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteItem from "../components/site-item"
import TodayDate from "../components/today-date"

const IndexPage = ({ data }) => (
  <Layout indexPage={true}>
    <SEO title={`All Bangla Newspapers List ${new Date().getFullYear()}`} />
    <TodayDate />
    <div className="top-newspapers mb50">
      <h2 className="text-center mb30">Top Newspapers in Bangladesh</h2>
      <div className="newspapers-grid">
        {data.top.nodes.map(topNewspaper => (
          <SiteItem
            key={topNewspaper.frontmatter.id}
            id={topNewspaper.frontmatter.id}
            url={topNewspaper.frontmatter.url}
            title={topNewspaper.frontmatter.title}
            slug={topNewspaper.fields.slug}
            embed={topNewspaper.frontmatter.embed}
          />
        ))}
      </div>
    </div>
    <div className="all-newspapers mt50 mb50">
      <h2 className="text-center mb30">All Bangla Newspapers in Bangladesh</h2>
      <div className="newspapers-grid">
        {data.all.nodes.map(newspaper => {
          if (includes(newspaper.frontmatter.types, 'bn')) {
            return (
              <SiteItem
                key={newspaper.frontmatter.id}
                id={newspaper.frontmatter.id}
                url={newspaper.frontmatter.url}
                title={newspaper.frontmatter.title}
                slug={newspaper.fields.slug}
                embed={newspaper.frontmatter.embed}
              />
            )
          }

          return ""
        })}
      </div>
    </div>
    <div className="all-en-newspapers mt50 mb50">
      <h2 className="text-center mb30">All English Newspapers in Bangladesh</h2>
      <div className="newspapers-grid">
        {data.all.nodes.map(newspaper => {
          if (includes(newspaper.frontmatter.types, 'en')) {
            return (
              <SiteItem
                key={newspaper.frontmatter.id}
                id={newspaper.frontmatter.id}
                url={newspaper.frontmatter.url}
                title={newspaper.frontmatter.title}
                slug={newspaper.fields.slug}
                embed={newspaper.frontmatter.embed}
              />
            )
          }

          return ""
        })}
      </div>
    </div>
    <div>
      <h2 className="text-center mb30">About All Newspapers</h2>
      <p>
        Bangladesh is a small and beautiful country. There is a large number of
        online newspapers. Remembering all newspapers names or addresses is
        really hard. There is good news! Get all Bangladeshi newspapers in a
        single place. Just remember or bookmark{" "}
        <a href="https://www.allnewspapers.xyz">allnewspapers.xyz</a> and forget
        all newspaper address. No need to search and remember the online
        newspaper's address. All Bangla and English newspapers listed on this
        site including national newspapers, local newspapers, online newspapers
        and magazines.
      </p>

      <h3>Bangla Newspapers in Bangladesh</h3>
      <p>
        Bangla (Bengali) is the mother language of Bangladesh. Most newspapers
        and magazines have written with Bangla language. That's why most people
        are reading Bangla newspapers. In this site, you will get{" "}
        <strong>all the Bangla newspapers list</strong>.
      </p>

      <h3>English Newspapers in Bangladesh</h3>
      <p>
        Bangla (Bengali) is the mother language of Bangladesh. But, English is
        often used and the secondary language in Bangladesh. There is enough
        amount of English newspapers. Also, a lot of newspapers have Bangla and
        English version both like prothom alo, jagonews24, etc.
      </p>
    </div>
    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    top: allMarkdownRemark(
      sort: {fields: frontmatter___rank}
      limit: 15
      filter: {frontmatter: {isDead: {ne: true}}}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          id
          title
          url
          embed
          types
        }
      }
    }
    all: allMarkdownRemark(
      sort: {fields: frontmatter___rank}
      skip: 15
      filter: {frontmatter: {isDead: {ne: true}}}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          id
          title
          url
          embed
          types
        }
      }
    }
  }
`
